import React from "react"
import ReactDOM from "react-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import ArtistCheckoutForm from "./ArtistCheckoutForm"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function ArtistBilling(props) {
  const { public_key, action } = props
  const stripePromise = loadStripe(public_key)

  return (
    <Elements stripe={stripePromise}>
      <ArtistCheckoutForm action={action} />
    </Elements>
  )
}
