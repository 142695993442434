import React from "react"

function CardSection({ onReady }) {
  return (
    <label className="font-bold text-normal">
      Card details
      <CardElement
        onReady={onReady}
        options={CARD_ELEMENT_OPTIONS}
        className="mt-1 p-2 bg-cool-gray-100 rounded border-gray-200 border"
      />
    </label>
  )
}

export default CardSection
