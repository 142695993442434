import React from "react"
import ReactDOM from "react-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import MemberCheckoutForm from "./MemberCheckoutForm"

export default function MemberCheckout(props) {
  const { public_key: publicKey, action } = props
  const stripePromise = loadStripe(publicKey)

  return (
    <Elements stripe={stripePromise}>
      <MemberCheckoutForm action={action} />
    </Elements>
  )
}
