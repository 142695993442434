function _fetch(url, method, body) {
  const csrf = document.querySelector("meta[name=csrf-token]").content

  return fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf,
    },
    body: JSON.stringify(body),
  }).then((result) => result.json())
}

function post(url, body) {
  return _fetch(url, "post", body)
}

export { post }
