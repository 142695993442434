import React from "react"
import ReactDOM from "react-dom"

const counts = [10, 25, 50, 100, 200, 500, 1000]
const costs = [7, 10, 15, 20]
const cut = 0.7

function estimateEarnings(count, cost) {
  return count * cost * cut
}

export default class EstimateEarnings extends React.Component {
  constructor() {
    super()

    this.state = {
      count: 50,
      cost: 10,
    }
  }

  get estimate() {
    const { cost, count } = this.state

    return cut * count * cost
  }

  updateEstimate({ cost, count }) {
    this.setState({
      cost: cost || this.state.cost,
      count: count || this.state.count,
    })
  }

  render() {
    return (
      <div className="flex flex-col align-stretch">
        <div class="max-w-xs rounded-md shadow-sm">
          <select
            className="block form-select w-full text-xl font-semibold transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            id="subscribers-count"
            onChange={e => this.updateEstimate({ count: e.target.value })}
            defaultValue={this.state.count}
          >
            {counts.map(count => (
              <option key={count} value={count}>
                {count} subscribers
              </option>
            ))}
          </select>
        </div>
        <div class="mt-2 max-w-xs rounded-md shadow-sm">
          <select
            className="block form-select w-full text-xl font-semibold transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            id="subscription-cost"
            onChange={e => this.updateEstimate({ cost: e.target.value })}
            defaultValue={this.state.cost}
          >
            {costs.map(cost => (
              <option key={cost} value={cost}>
                ${cost}/mo
              </option>
            ))}
          </select>
        </div>
        <div className="my-3">
          <p className="text-lg font-bold text-center text-gray-900">
            You could earn
          </p>
          <p className="earnings text-2xl my-1 font-bold text-center text-gray-900">
            $
            <span id="earnings-estimate">{this.estimate.toLocaleString()}</span>
            &nbsp;/&nbsp;month
          </p>
          <p className="font-normal text-sm text-gray-600 text-center">
            (after print and processing fees)
          </p>
        </div>
      </div>
    )
  }
}
