import React, { useState } from "react"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"

import CardSection from "./CardSection"
import { post } from "./request"

export default function MemberCheckoutForm({ action, sessionId }) {
  const stripe = useStripe()
  const elements = useElements()
  const [paymentError, setPaymentError] = useState(null)
  const [customerEmail, setEmail] = useState("")

  const [ref, setRef] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    post(action, {
      checkout: {
        customer_email: customerEmail,
      },
    })
      .then(({ session_id }) => {
        stripe.redirectToCheckout({ sessionId: session_id })
      })
      .then((result) => {})
      .catch((e) => {
        setPaymentError(
          typeof e == "string" ? e : "Something went wrong. Please try again."
        )
      })
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-stretch">
      <label htmlFor="email" className="font-bold">
        What's your email address?
      </label>
      <input
        type="text"
        placeholder="jdoe@example.com"
        value={customerEmail}
        onChange={(e) => setEmail(e.target.value)}
        name="email"
        className="mt-2 shadow-sm form-input block w-full sm:text-sm sm:leading-5"
        id="email"
      />
      <button
        type="submit"
        className="mt-2 flex shadow-md items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
      >
        Join Now
      </button>
    </form>
  )
}
