import React from "react"
import { useForm } from "@statickit/react"
import { createClient } from "@statickit/core"

const client = createClient({ site: "75cd19d7f2b2" })
client.startBrowserSession()

function MailingListForm() {
  const [state, handleSubmit] = useForm("optInForm", { client })

  if (state.succeeded) {
    return (
      <div className="text-center pt-2 pb-3">Thank you for signing up!</div>
    )
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col md:flex-row justify-center items-stretch"
    >
      <label htmlFor="email" className="hidden">
        Get notified when we launch
      </label>
      <input
        id="email"
        type="email"
        name="email"
        className="text-input flex-grow border-r-0 rounded-r-none hidden md:inline"
        placeholder="john@example.com"
      />
      <button
        type="submit"
        disabled={state.submitting}
        className="rounded-sm bg-blue=500 rounded-l-none bg-blue-500 px-5 py-1 text-white font-bold hidden md:inline"
      >
        Sign Up
      </button>
      <input
        id="email"
        type="email"
        name="email"
        className="text-input flex-grow inline mb-2 md:hidden w-full"
        placeholder="john@example.com"
      />
      <button
        type="submit"
        disabled={state.submitting}
        className="rounded-sm bg-blue=500 bg-blue-500 px-5 py-1 text-white font-bold inline md:hidden"
      >
        Sign Up
      </button>
    </form>
  )
}

export default MailingListForm
