import React, { useState } from "react"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"

import CardSection from "./CardSection"

function post(url, body) {
  const csrf = document.querySelector("meta[name=csrf-token]").content

  return fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf,
    },
    body: JSON.stringify(body),
  }).then((result) => result.json())
}

export default function ArtistCheckoutForm({ action }) {
  const stripe = useStripe()
  const elements = useElements()
  const [paymentError, setPaymentError] = useState(null)
  const [paymentSuccess, setPaymentSuccess] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const [ref, setRef] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitting(true)

    if (!stripe || !elements) {
      return
    }

    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      })
      .then((result) => {
        if (result.error) {
          return Promise.reject(result.error.message)
        }

        return post(action, { payment_method: { id: result.paymentMethod.id } })
      })
      .then((result) => {
        if (!result.success) {
          return Promise.reject(result.error)
        }

        const { client_secret, status } = result

        if (status === "requires_action") {
          return stripe.confirmCardPayment(client_secret)
        }

        return result
      })
      .then((result) => {
        if (result.error) {
          return Promise.reject(result.error)
        }

        ref.clear()
        setSubmitting(false)
        setPaymentSuccess(result.success)
      })
      .catch((e) => {
        setSubmitting(false)
        setPaymentError(
          typeof e == "string" ? e : "Something went wrong. Please try again."
        )
      })
  }

  const disabled = !stripe || submitting

  const buttonClass =
    "inline-flex items-center px-4 py-2 border border-transparent text-normal leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
  const buttonClassDisabled = " opacity-50 cursor-not-allowed"

  return (
    <form onSubmit={handleSubmit}>
      {paymentSuccess ? (
        <p className="mt-2 text-green-600">{paymentSuccess}</p>
      ) : (
        <CardSection onReady={(e) => setRef(e)} />
      )}

      {paymentError && <p className="mt-2 text-red-600">{paymentError}</p>}
      <span className="mt-2 inline-flex rounded-md shadow-sm">
        <button
          type="submit"
          disabled={disabled}
          className={disabled ? buttonClass + buttonClassDisabled : buttonClass}
        >
          Confirm Billing Information
        </button>
      </span>
    </form>
  )
}
