import React from "react"
import { CardElement } from "@stripe/react-stripe-js"

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
}

function CardSection({ onReady }) {
  return (
    <label className="font-bold text-normal">
      Card details
      <CardElement
        onReady={onReady}
        options={CARD_ELEMENT_OPTIONS}
        className="mt-1 p-2 bg-cool-gray-100 rounded border-gray-200 border"
      />
    </label>
  )
}

export default CardSection
